import { render, staticRenderFns } from "./Storages.vue?vue&type=template&id=70c49c59&scoped=true"
import script from "./Storages.vue?vue&type=script&lang=js"
export * from "./Storages.vue?vue&type=script&lang=js"
import style0 from "./Storages.vue?vue&type=style&index=0&id=70c49c59&prod&lang=scss&scoped=true"
import style1 from "./Storages.vue?vue&type=style&index=1&id=70c49c59&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70c49c59",
  null
  
)

export default component.exports